.profile .social-link {
    display: flex;
    align-items: center;
    margin: 20px;
    
}

.profile .social-link img {
    width: 40px;
    height: 100%;
    margin-right: 10px;
}
.profile .social-link p {
    margin-bottom: 0;
    color: gray;

}

.profile .social-link p:hover {
    color: white;
    transition: ease-in-out 0.2s;

}

.profile .social-link a {
    cursor: url("../assets/cursor1.png"), auto;
}