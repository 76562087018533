.tabs .tab-title {
    display: flex;
    align-items: center;
}

.tabs .tab-title .tab-icon {
    margin-right: 4px;
}

.tabs .tab-title:hover {
    cursor: url("../assets/cursor1.png"), auto;
}

.tabs .tab-text{
    margin-bottom: 0 !important;
}

#journey > .tabs {
    display: flex;
}

.profile > .tabs > .tab-titles {
    display: flex;
    padding: 1vh 2vw;
}

#journey > .tabs > .tab-titles {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
}

.profile>.tabs>.tab-titles .tab-title {
    margin-right: 15px;
    padding: 5px 10px;
    height: 52px;
}

/* .profile>.tabs>.tab-titles .tab-icon img{
    width: 25px;
} */

.profile>.tabs>.tab-titles .tab-icon{
    max-width: 30px;
}

.profile>.tabs>.tab-titles .tab-text:hover {
    color: lightgray;
    transition: ease-in-out 0.2s;
}

#journey > .tabs .tab-titles {
    margin-right: 10px;
}

#journey > .tabs .tab-title {
    width: 34px;
    margin-bottom: 12px;
}


#journey .tabs .tab-content {
    font-size: 24px;
    padding: 5px 10px;

}

#journey .tabs .tab-content .title {
    color: grey;
}

#journey .tabs .inline-link {
    text-decoration: underline;
    color: grey;
}

#journey .tabs .content-link {
    color: white;
}

#journey .tabs .inline-link:hover {
    background-color: lightskyblue;
}



/* mobile */
@media (max-width: 480px) {
    #journey .tabs .tab-content {
        font-size: 20px;
    }

    #journey > .tabs {
        flex-direction: column;
    }

    #journey > .tabs > .tab-titles {
        flex-direction: row;
    }

    #journey > .tabs .tab-title {
        margin-right: 12px;
        height: 42px;
    }

    /* .profile>.tabs>.tab-titles .tab-icon{
        max-height: 24px;
    } */

    .profile>.tabs>.tab-titles .tab-text {
        font-size: 20px;
    }

    .profile>.tabs>.tab-titles .tab-title {
        margin-right: 5px;
    }



}