/* font-family: 'VT323', monospace; */
/* font-family: 'Press Start 2P', cursive; */

html {
  background-color: black;
  font-family: 'VT323', monospace;
  color: white;
}

.home-content {
  height: 90vh;
  width: 100vw;
  background-color: black;
}

.home-page {
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: hidden;
}

.content-container {
  margin: 5vh 3vw;
  text-align: justify;
}

.flex {
  display: flex;
}

.content-container .text {
  line-height: 1.2;
}

.layout-container,
.full-layout-container {
  padding: 1vh 2vw;
}

.layout-container {
  max-width: 800px;
}

p,
button {
  color: white;
  font-family: 'VT323', monospace;
}

.button {
  cursor: url('../assets/cursor1.png'), auto;
}

.link,
a {
  text-decoration: none;
  color: white;
}

/* mobile */
@media (max-width: 480px) {
  .content-container {
    line-height: 1;
    flex-direction: column;
  }

  .layout-container {
    width: 94vw;
  }
}

@media (min-width: 481px) and (max-width: 870px) {
  .content-container {
    flex-direction: column;
  }

  .layout-container {
    width: 94vw;
  }
}
