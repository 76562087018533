.game {
    display: flex;
    justify-content: center;
    max-width: 1024px;
    margin: auto;

}

.cell:hover {
    border: 1px solid grey;
    cursor: url('../assets/cursor1.png'), auto; 
}
.grid-wrapper {
    display: flex;
    flex-direction: row;
}

.cols-wrapper {
    display: flex;
    flex-direction: column;
}

.game-menu {
    position: fixed;
    left: 5vw;
    bottom: 5vh;
    display: flex;
}

.game .menu-button {
    color: lightgrey;
    height: 62px;
}

.bounce {
    animation: bounce 1s .5s;
    transform: scale(0.85);
  }
  
  @keyframes bounce {
    0% { transform: scale(1.1); opacity: 1 }
    50% { transform: scale(1.6); opacity: .7; }
    60% { transform: scale(0.6); opacity: 1 }
    80% { transform: scale(0.95) }
    100% { transform: scale(0.85) }
  }

.menu-button img {
    height: 40px;
}

.game .toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.game .intro {
    color: grey;
    font-family: 'VT323', monospace; 
    font-size: 30px;
    padding-left: 1vw;
}

.game .tooltip {
    background-color: black;
    border: 2px dashed white;
    padding: 3px 10px;
    position: absolute;
    bottom: 70px;
    font-size: 25px;
    z-index: 2;
}

.game .actions {
    padding-left: 1vw;
    display: flex;
}

.actions button,
.actions p {
    color: lightgray;
    font-family: 'VT323', monospace; 
    font-size: 30px;
    margin-right: 2vw;
}

.actions .speed-button {
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 0;
}

.actions #run-button {
    width: 48px;
}


.actions button:hover{
    color: white;
    cursor: url('../assets/cursor1.png'), auto; 
    transform: scale(1.05);
    transition: all ease-in-out 0.1s;
}

.game .intro:hover {
    color: white;
    cursor: url('../assets/cursor1.png'), auto; 
}

/* mobile */
@media (max-width: 400px) {
    .actions button, .actions p, 
    .game .intro,
    .game .tooltip{
        font-size: 20px;
    }

    .actions #run-button {
        width: 32px;
    }

    .menu-button img {
        height: 30px;
    }

}
