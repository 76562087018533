.card {
    position: relative;
    margin-right: 20px;
}

.card .card-name p {
    display: inline-block;
}

.card .front,
.card .back{
    color: lightgrey;
    padding: 3px 5px;
}

.card .front:hover,
.card .back:hover {
    color: white;
    cursor: url('../assets/cursor1.png'), auto;
}

.card .tooltip {
    background-color: black;
    border: 2px dashed white;
    padding: 3px 10px;
    position: absolute;
    font-size: 25px;
    z-index: 2;
}