.project-page .projects {
  display: flex;
  flex-wrap: wrap;
}


#color-switcher {
    font-size: 16px;
    border: none;
    width: 110px;
}

#color-switcher .color-name{
    margin-left: 4px;
}

#color-switcher:hover {
    border: none;
    cursor: url("../assets/cursor1.png"), auto;

}

.projects .project-card {
  font-size: 25px;
  padding: 15px 10px;
  width: 180px;
  height: 180px;
  margin: 15px;
  position: relative;
}

.projects .project-card .desc {
  margin-top: 10px;
  font-size: 18px;
  color: black;
}

.projects .project-card .flag {
  font-size: 18px;
  position: absolute;
  bottom: 10px;
}

.project-page .color-switcher:hover,
.projects .project-card:hover,
.projects .detail-content .close-button,
.projects .detail-content #source a:hover
 {
  cursor: url("../assets/cursor1.png"), auto;
}

.projects .detail-content #tool:hover,
.projects .detail-content #title:hover,
.projects .detail-content #detail:hover {
  cursor: default;
}

.projects .project-detail {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 20vh;
  /* Location of the box */
  left: 0;
  top: 0;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.projects .project-detail .detail-content {
  font-size: 23px;
  margin: auto;
  padding: 50px 30px;
  width: 600px;
  height: 600px;
  line-height: 1.1;
  overflow: scroll;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.detail-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.detail-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.projects .detail-content .close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  text-decoration: underline;
  font-size: 22px;
  
}

.projects .detail-content .project-img {
  margin: auto;
  display: block;
  margin-bottom: 20px;

}

.projects .detail-content #source a:hover {
  color: white;
  transition: ease-in-out 0.2s;
}

.projects .detail-content .close-button:hover,
.projects .detail-content #tool:hover,
.projects .detail-content #title:hover,
.projects .detail-content #detail:hover {
  color: black;
  transition: ease-in-out 0.2s;
}

.projects .detail-content .part {
  margin-bottom: 15px;
}

.projects .detail-content #title {
  font-size: 28px;
  margin-top: 10px;
}

.projects .detail-content #tools p,
.projects .detail-content #tool,
.projects .detail-content #source {
  font-size: 20px;
}

.projects .detail-content #tools > p,
.projects .detail-content #source,
.projects .detail-content #source a {
  color: black;
}

.projects .detail-content #tool {
  color: white;
  margin-right: 10px;
}

.projects .detail-content #source a {
  margin-left: 8px;
  text-decoration: underline;
}

.projects .detail-content .tool-items {
  display: flex;
  flex-wrap: wrap;
}

/* breakpoint */
@media (max-width: 600px) {

  .projects .project-detail {
    padding-top: 10vh;
  }
  
  .projects .project-detail .detail-content {
    font-size: 18px;
    width: 300px;
    height: 500px;
    line-height: 1;
    padding: 25px;
  }

  .projects .detail-content #title {
      margin-top: 0px;
    font-size: 24px;
  }

  .projects .detail-content #tools {
    max-width: 300px;
  }

  .projects .detail-content .close-button {
    font-size: 18px;
    margin-left: 220px;
  }

  .projects .detail-content #tools p,
  .projects .detail-content #tool,
  .projects .detail-content #source {
    font-size: 18px;
  }
}
