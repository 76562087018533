.header {
    height: 10vh;
    background-color: black;
    justify-content: flex-end;
    padding-right: 3vw;
    padding-bottom: 3px;
    display: flex;
}

.header .nav {
    display: flex;
    align-items: flex-end;

}

.header .toggle {
    margin-bottom: 4px;
}

.header .menu-button {
    height: 40px;
    color: lightgrey;
}

.menu-button:hover {
    cursor: url('../assets/cursor1.png'), auto; 
    transform: scale(1.1);
    transition: ease-in-out 0.1s;
}

.header .nav-link {
    color: gray;
    font-family: 'VT323', monospace; 
    font-size: 30px;
    margin-right: 3vw;
    padding: 5px;
}

.nav-link:hover {
    color: white;
    transition: ease-in-out 0.1s;
    cursor: url('../assets/cursor1.png'), auto;
}

@media (max-width: 400px) {
    .header {
        padding-bottom: 7px;
        padding-right: 20px;
    }

    .header .toggle {
        margin-bottom: 12px;
    }

    .header .nav-link {
        font-size: 25px;
    }
}