.filters {
    display: flex;
}

.filters .filter-control {
    width: 35vw;

}

.filters .filter-control .prompt {
    font-size: 20px;
    padding: 0 5px;
    margin-right: 5px;
}

.filters .projects {
    width: 60vw;
}
.filters .button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.filters .filter-button {
    color: lightgray;
    font-size: 22px;
}

/* breakpoint */
@media (max-width: 770px) {
    .filters {
        flex-direction: column;
    }

    .filters .filter-control,
    .filters .projects {
        width: 90vw;
    }

}