
.about-page .content {
  font-size: 34px;

}

.about-page .content p {
  margin-bottom: 15px;
}

.content-container .text {
  line-height: 1.2;
}

.about-page .buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

}

.layout-container .text {
  max-width: 800px;
}

#start .color-switcher:hover {
  cursor: url("../assets/cursor1.png"), auto;
}

#setting .story {
  width: 50vw;
}

#setting .play {
  width: 40vw;
  text-align: center;
}

#setting .continue-button {
  margin: auto;
  font-size: 50px;
}

#setting .continue-button:hover {
  color: white; 
}

#setting .choices {
  text-align: left;
}

#setting .choices .prompt p {
  color: #6f8fc3;
}

.content-container .button,
.content-container .confirm-button {
  font-size: 34px;
  padding: 5px 10px;
}


/* mobile */
@media (max-width: 480px) {
  .about-page .content,
  .about-page .button {
    font-size: 24px;
  }
  
  .content-container {
    line-height: 1;
    flex-direction: column;
  }

  #start .text {
    margin-left: 1vw;
    margin-right: 1vw;
  }

  /* #setting is needed here, otherwise it will be overriden */
  #setting .layout-container{
    width: 94vw;
  }
}


